.dialog {

  &--inside {
    border: solid 1px transparent;
    width: 22em;
    padding: 1.5em 2em;
    border-radius: $radius;
    background: $fill;
    text-align: center;
    box-shadow: 0 4px 4px 2px #00000099;
  }

  &--icon {
    text-align: center;
    padding-bottom: $xspd;
  }

  &--title {
    padding-bottom: 1.5em;
  }

  &--message {
    white-space: pre-wrap;

    &--align-center {
      text-align: center;
    }
    &--align-left {
      text-align: left;
    }
  }

  &--wrapper {
    padding: 1em 0 0.5em;
    & > * {
      margin: 0 0.5em;
    }
  }
}
