.text {
  line-height: 1.25em;

  /* Шрифт */
  &--gotham-medium {
    font-family: $gothamMedium;
  }
  &--gotham-black {
    font-family: $gothamBlack;
  }

  &--gotham-light {
    font-family: $gothamLight;
  }

  &--gotham-book {
    font-family: $gothamBook;
  }

  &--light {
    font-family: $gothamLight;
  }

  &--bold {
    font-family: $gothamBlack;
  }

  &--helvetica {
    font-family: $helvetica;
  }

  &--white-space {
    white-space: pre-line;
  }

  /* Цвета */
  &--white {
    color: $base;
  }
  &--black {
    color: $black;
  }
  &--pink {
    color: $pink;
  }
  &--grey {
    color: $grey;
  }
  &--green {
    color: $green;
  }
  &--red {
    color: $error;
  }

  /* Размеры */
  &--size10 {
    font-size: $xxxs;
  }
  &--size12 {
    font-size: $xxs;
  }
  &--size14 {
    font-size: $xs;
  }
  &--default {
    font-size: $md;
  }
  &--size18 {
    font-size: $xl;
  }
  &--size20 {
    font-size: $xxl;
  }
  &--size22 {
    font-size: $xxxl;
  }
  &--size24 {
    font-size: $hxxs;
  }
  &--size30 {
    font-size: $hxs;
  }
  &--size36 {
    font-size: $hxl;
  }
  &--size40 {
    font-size: $hxxl;
  }

  /* Трансформации */
  &--transform {
    &--uppercase {
      text-transform: uppercase;
    }
  }
}
