.flex {
  display: flex;

  &--justify-content-center {
    justify-content: center;
  }

  &--justify-content-space-between {
    justify-content: space-between;
  }

  &--justify-content-flex-end {
    justify-content: flex-end;
  }

  &--justify-content-flex-start {
    justify-content: flex-start;
  }

  &--full-width {
    width: 100%;
  }

  &--full-Height {
    height: 100%;
  }

  &--align-items-center {
    align-items: center;
  }

  &--align-items-flex-start {
    align-items: flex-start;
  }

  &--align-items-flex-end {
    align-items: flex-end;
  }

  &--column {
    flex-direction: column;
  }

  &--row {
    flex-direction: row;
  }

  &--row-reverse {
    flex-direction: row-reverse;
  }
}

