@import "fonts/fonts";

// Sizes Text
$xxxs: 0.675em; // 10px
$xxs: 0.75em;   // 12px
$xs: 0.875em;   // 14px
$md: 1em;       // 16px
$xl: 1.125em;   // 18px
$xxl: 1.25em;   // 20px
$xxxl: 1.375em; // 22px

// Sizes Heading
$hxxs: 1.5em;     // 24px
$hxs: 2em;        // 30px
$hxl: 2.25em;     // 36px
$hxxl: 2.5em;     // 40px

// Padding
$xspd: 0.5em;
$spd: 0.75em;
$mpd: 1em;
$lpd: 1.5em;
$xlpd: 1.75em;
$xxlpd: 2em;

// Colors
$base: white;
$black: black;
$pink: #ec1d91;
$error: #D55757;
$inputBgGrey: #D9D9D9;
$darkGrey: #606060;
$grey: #e0e0e0;
$fill: #1e201f;
$green: #21CE98;
$orange: #f6c656;
$chatColorDark: #C4C4C4;
$chatColorWhite: #E5E5E5;

// Font

$gothamBlack: 'Gotham-Black';
$gothamMedium: 'Gotham-Medium';
$gothamLight: 'Gotham-Ligth';
$gothamBook: 'Gotham-Book';
$baseFont: $gothamMedium, 'Verdana';
$helvetica: 'Helvetica';

$radius: 4px;
$radiusBig: 12px;

// Layout sizes

$sideMenuWidth: 9.625em;
$headerHeight: 3.75em;

@keyframes sSteps {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}
