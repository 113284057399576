@keyframes showBlock {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    opacity: 1;
    max-height: 50em;
  }
}

@keyframes showOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.chat-bot {
  &--wrapper {
    position: fixed;
    right: 2.5em;
    bottom: 2.5em;
    background: $base;
    border-radius: 8px;
    border: solid 1px $black;
    width: 42em;
    animation: showBlock ease-out 0.3s 1;
  }

  &--loading {
    display: inline-block;
    width: auto;
    font-family: $gothamBook;
    font-size: $xl;
    line-height: $xl;
    clip-path: inset(0 0.5em 0 0);
    animation: sSteps 1s steps(4) infinite;

    &--wrapper {
      width: 20%;
      background: $chatColorDark;
      border-radius: 24px 24px 24px 0;
      padding: 0.75em;
      margin-bottom: 1em;
    }
  }

  &--trigger {
    transition: all 0.3s ease-out;
    border: none;
    background: transparent;
    width: 7.25em;
    height: 7.25em;
    vertical-align: top;
    cursor: pointer;
    position: fixed;
    right: 3em;
    bottom: 3em;
    opacity: 1;
    scale: 1;

    & > * {
      width: 100%;
      height: 100%;
    }

    &--hidden {
      opacity: 0;
      scale: 2;
    }
  }

  &--header {
    height: 3.125em;
    position: relative;
    border-bottom: solid 1px $darkGrey;

    &--close {
      border: none;
      padding: 0.5em;
      background: transparent;
      position: absolute;
      right: 1em;
      top: 1em;
      cursor: pointer;
      vertical-align: top;
    }
  }

  &--body {
    height: 21.5em;
    overflow: auto;
    padding: 1em 2em;
    user-select: text;
    animation: showOpacity ease-in 0.5s 1;

    &--message {
      padding: 0.75em;
      margin-bottom: 1em;
      max-width: 75%;

      &--bot {
        background: $chatColorDark;
        border-radius: 24px 24px 24px 0;
      }

      &--human {
        background: $chatColorWhite;
        align-self: flex-end;
        border-radius: 24px 24px 0 24px;
      }
    }
  }

  &--textarea {
    border-top: solid 1px $darkGrey;
    padding: 1em 2em;
    position: relative;

    &--input {
      border: none;
      width: 100%;
      padding: 0;
      margin: 0;
      font-size: 1.125em;
      font-family: $helvetica;
      resize: none;
      background: transparent;
      outline: none;
    }

    &--button {
      width: 3.5em;
      height: 3.5em;
      background: $pink;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      right: 1em;
      bottom: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;

      * {
        margin-top: 2px;
      }
    }
  }
}
