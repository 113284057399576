.side-menu {
  padding-top:2em;
  background-color: $black;

  &-link {
    transition: opacity 0.3s ease-out;
    display: block;
    padding: 0.5em 1em 0.5em 1.5em;
    margin-left: 0.5em;
    color: $base;
    text-decoration: none;
    border-radius: $radiusBig 0 0 $radiusBig;
    &:hover {
      opacity: 0.8;
    }
  }

  &-selected {
    background-color: $pink;
  }
}
