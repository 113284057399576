.switch {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;

  &--layer {
    transition: all 0.3s ease-out;
    background: $inputBgGrey;
    border-radius: 15px;
    position: relative;
    width: 3.75em;
    height: 1.875em;
    cursor: pointer;
    &--checked {
      background: $green;
    }
  }

  &--tumbler {
    transition: all 0.3s ease-out;
    position: absolute;
    width: 1.5em;
    height: 1.5em;
    top: 0.1875em;
    left: 0.1875em;
    &--checked {
      left: calc(100% - 0.22em - 1.5em);
    }
  }
}
