.route-button {
  &--back--button {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    background-color: $pink;
    border-radius: 6px;
    text-transform: uppercase;
    font-family: $gothamBlack;
    color: $base;
    border: none;
    position: relative;
    padding: 0.25em 0.35em 0.25em 1.25em;
    margin-bottom: 0.5em;
    font-size: $xl;
  }

  &--back--icon {
    position: absolute;
    left: 0.25em;
    top: 50%;
    transform: translateY(-50%);
  }
}
