.main-layout {
  &-header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 0;
    right: 0;
    top: 0;
    padding: 0 1.5em;
    height: $headerHeight;
    background-color: $black;
  }

  &-logo {
    color: $base;
    text-decoration: none;
  }

  &-menu {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: $headerHeight;
    width: $sideMenuWidth;
    background-color: $black;
  }

  &-body {
    position: absolute;
    top: $headerHeight;
    left: $sideMenuWidth;
    right: 0;
    bottom: 0;
    background-color: $fill;
  }
}
