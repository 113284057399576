.home-page {
  background-color: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  &--button-between {
    padding-top: $mpd;
    width: 100%;
    display: flex;
    justify-content: center;
    & > * {
      margin: 0 0.5em;
    }
  }

  &-wrapper {
    width: 45.5em;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-card {
    width: 22em;
    height: 14em;
    display: flex;
    margin-bottom: 1.5em;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;

    &-item {
      transition: all 0.3s ease-out;
      width: 22em;
      position: relative;
      border: none;
      display: block;
      &--pink {
        background-color: $pink;
      }
      &:hover {
        transform: scale(1.05);
      }
    }

    &-wide {
      height: 14em;
    }

    &-half {
      height: 6.25em;
    }
  }

  &-img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  &-text {
    font-family: $gothamBlack;
    white-space: pre-wrap;
    user-select: none;
    line-height: 1em;

    &-left-bottom {
      position: absolute;
      left: 0.75em;
      bottom: 0.75em;
    }
    &-right-bottom {
      position: absolute;
      text-align: right;
      right: 0.75em;
      bottom: 0.75em;
    }
  }
}
