.join-us-page {
  width: 45.5em;
  margin: 2em auto;

  &--sub-title {
    padding:$xxlpd 0 $lpd 0;
  }

  &--text {
    padding:0 0 $lpd 0;
  }

  &--half-item {
    width: 48%;
  }

  &--img {
    margin: $xxlpd 0 $lpd;
    img {
      width: 100%;
      vertical-align: top;
    }
  }

  &--puzzle {
    padding: 0 $lpd;

    &--item {
      width: 22%;
      text-align: center;
      padding: $xspd $xspd / 2;
      background-color: $pink;

      &--text {
        white-space: pre-wrap;
      }
    }
  }

  &--button-wrapper {
    padding: $lpd 0 0;
  }

  &--button {
    cursor: pointer;
    background-color: $pink;
    border-radius: 6px;
    text-transform: uppercase;
    font-family: $gothamBlack;
    color: $base;
    border: none;
    padding: 0.5em 0.35em;
    font-size: $xl;
  }

  &--qr-mail {
    letter-spacing: 0.0325em;
  }

  &--qr-code {
    padding:$lpd 0;

    &--bottom-text {
      padding-top: 0.5em;
    }
    &--img {
      margin:$xspd 0;
      width: 36%;
    }
    a {
      color: $base;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
