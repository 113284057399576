.kavaca-ion-page {
  width: 45.5em;
  margin: 2em auto;

  &--sub-title {
    padding:$xxlpd 0 $lpd 0;
  }

  &--img {
    margin: $xxlpd 0 $lpd;
    border-left: solid 0.25em $pink;
    img {
      width: 100%;
      vertical-align: top;
    }
  }

  &--half-item {
    width: 48%;
  }

  &--list {
    padding: $xspd 0 $xspd $xlpd;
    position: relative;
    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-40%);
      left: $xspd;
      width: 0.5em;
      height: 0.5em;
      background-color: $pink;
    }
  }

  &--button-wrapper {
    padding: ($lpd * 2) 0;
  }

  &--button {
    cursor: pointer;
    background-color: $pink;
    border-radius: 6px;
    text-transform: uppercase;
    font-family: $gothamBlack;
    color: $base;
    border: none;
    padding: 0.5em 0.35em;
    font-size: $xl;
  }
}
