.software-guide-page {
    width: 30em;
    margin: 2em auto;

    &--video {
        padding: 1.5em 0;
    }

    &--title-text {
        padding-bottom: 1em;
    }

    &--title-download {
        padding-top: 1em;
        padding-bottom: 3em;
    }

    &--white-space {
        white-space: pre-wrap;
        padding-bottom: 2.5em;
        & > * {
            line-height: 1.5em;
        }
    }

    &--link {
        color:white;
        &:hover {
            text-decoration: none;
        }
    }
}
