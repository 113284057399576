.global {

  &--log {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2em;
    color: $base;
    word-break: break-all;
    pointer-events: none;
    font-size: $xxxs;
    display: none;
  }
}
